<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import TimeLine from './TimeLine.vue'
import ParcelHeader from './ParcelHeader.vue'

export default {
	name: 'ParcelTimeline',
	components: { ParcelHeader, TimeLine },
	props: {
		deliveries: {
			type: Array,
			required: true
		}
	},
	computed: {
		...mapState('orders', ['reasons']),
		processedDeliveries() {
			return _.map(this.deliveries, delivery => ({
				...delivery,
				reason: this.getReason(delivery)
			}))
		}
	},
	methods: {
		getReason(delivery) {
			const lastSubmission = _.last(delivery.formSubmissions)
			const reasons = ['REASONS_FAILURE', 'REASONS_SUCCESS']
			const formTagsWithReasons = [
				'DROP_OFF_FAILURE',
				'DROP_OFF_SUCCESS',
				'PICKUP_FAIL'
			]
			if (_.includes(formTagsWithReasons, lastSubmission?.tag)) {
				// Get failure/success reason.
				// used includes to cover all form field tag that has REASONS_FAILURE and REASONS_SUCCESS.
				// Just continue following this naming convention for form-field tags
				const reasonField = _.find(lastSubmission.fields, field =>
					_.includes(reasons, field.tag)
				)
				if (reasonField) {
					const clientId = _.get(this.delivery, `0.${clientId}`)
					const reason = _.get(reasonField, 'values.0')
					const client =
						clientId in this.reasons ? clientId : 'COMMON'
					return _.get(this.reasons, `${client}.${reason}`, reason)
				}
			}
			return ''
		}
	}
}
</script>

<template>
	<q-list expand-separator v-if="deliveries">
		<q-expansion-item
			v-for="delivery in processedDeliveries"
			:key="delivery.id"
		>
			<template #header>
				<parcel-header :delivery="delivery" />
			</template>

			<q-card>
				<q-card-section>
					<time-line :delivery="delivery" />
				</q-card-section>
			</q-card>
		</q-expansion-item>
	</q-list>
</template>
