<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
	name: 'TimeLine',
	props: {
		delivery: {
			type: Object,
			required: true
		}
	},
	computed: {
		...mapGetters('orders', ['formatDate']),
		deliveryCreatedDate() {
			return this.formatDate(this.delivery.createdAt)
		}
	},
	methods: {
		courierInfo(user) {
			const name = _.get(user, 'info.name', '')
			const phone = _.get(user, 'aliases.0.id', '')
			return `${name} ${phone}`
		},
		openImagTab(url) {
			window.open(url)
		},
		getRemarks(submission) {
			return _.chain(submission)
				.get('fields')
				.find({ tag: 'REMARKS' })
				.get('values.0')
				.replace(/\n/g, ' ')
				.value()
		},
		getProofsOfDelivery(formSubmissionFields = []) {
			const validFieldTypeOrTag = ['PHOTO', 'SIGNATURE', 'BARCODE']

			/** @type {{tag: string,values: string[], type?: string}[]} */
			const fieldsWithPhoto = _.filter(
				formSubmissionFields,
				({ type, tag }) => {
					return (
						_.includes(validFieldTypeOrTag, type) ||
						/PHOTO|SIGNATURE|BARCODE/i.test(tag)
					)
				}
			)

			fieldsWithPhoto.sort(field => {
				return field.type === 'SIGNATURE' ||
					/SIGNATURE/i.test(field.tag)
					? 1
					: -1
			})

			/** @type {(string|undefined[])[]} */
			const result = _.map(fieldsWithPhoto, field => {
				if (field.type === 'BARCODE' || /BARCODE/i.test(field.tag)) {
					return _.get(field, 'meta.0.0.frame')
				}
				return field.values
			})

			return _.compact(_.flatten(result))
		}
	}
}
</script>

<template>
	<q-timeline>
		<q-timeline-entry heading>Logs</q-timeline-entry>
		<q-timeline-entry title="Delivery Requested">
			{{ deliveryCreatedDate }}
		</q-timeline-entry>
		<q-timeline-entry
			v-for="(submission, i) in delivery.formSubmissions"
			:key="submission.id"
			:title="submission.tag"
			:set="(remark = getRemarks(submission))"
		>
			<div>{{ formatDate(submission.createdAt) }}</div>
			<div v-if="i === delivery.formSubmissions.length - 1">
				<b>{{ delivery.reason }}</b>
			</div>
			<div v-if="remark">Courier remark: {{ remark }}</div>
			<div v-if="submission.userId">
				Courier:
				<b>{{ courierInfo(submission.user) }}</b>
			</div>
			<div class="proof-of-delivery">
				<img
					v-for="proof in getProofsOfDelivery(submission.fields)"
					loading="lazy"
					:src="proof"
					:key="proof"
					@click="openImagTab(proof)"
				/>
			</div>
		</q-timeline-entry>
	</q-timeline>
</template>

<style scoped lang="scss">
.proof-of-delivery {
	display: flex;
	flex-wrap: wrap;

	img {
		width: 200px;
		height: 200px;
		object-fit: cover;
		cursor: pointer;
		margin: 0 10px 10px 0;
		border: 1px solid #ccc;

		&[lazy='loading'] {
			background: #eee;
			border-color: transparent;
		}
	}
}
</style>
