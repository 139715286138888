<template>
	<div id="order-user-search">
		<!-- <button
			v-if="users.length === 0"
			:disabled="processing"
			class="md-button trigger-matchmaking"
			@click="triggerMatchmaking()"
		>
			{{ processing ? '🧠 working...' : '⚙️ trigger auto assign' }}
		</button> -->

		<table id="users">
			<tr>
				<th>Name</th>
				<th>Phone</th>
				<th />
			</tr>
			<tr v-for="(user, k) in users" :key="k">
				<td>{{ user.name }}</td>
				<td>{{ user.phone }}</td>
				<td id="remove" @click="remove(user.id)">❌</td>
			</tr>
		</table>
		<div id="search">
			<select id="types" v-model="aliasType">
				<option v-for="(type, k) in types" :key="k">{{ type }}</option>
			</select>
			<input
				v-model="searchKey"
				class="searchText"
				type="text"
				@keyup="search"
			/>
		</div>
		<table id="result">
			<tr v-for="(user, k) in results" :key="k">
				<td>
					{{
						`${user.name}${
							user.isVerified ? '' : ' Unverified(⚠️)'
						}`
					}}
				</td>
				<td>{{ user.phone }}</td>
				<td v-if="user.isVerified">
					<button id="add" @click="add(user)">➕</button>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
import _ from 'lodash'
import axiosApiClient from '../api/axiosApiClient'
import log from '@/lib/utils/log'
import { getUsers } from '@/api/users-api'

function formatOrderUser(order) {
	const { users } = order

	return (users || []).map(user => {
		const phone = (user.aliases.find(i => i.type === 'PHONE') || {}).id
		return { id: user.id, name: user.info.name, phone }
	})
}

export default {
	name: 'OrderRequestUserSearch',
	data() {
		const ctx = {
			users: [],
			types: ['PHONE', 'EMAIL', 'GOOGLE', 'TELEGRAM'],
			results: [],
			aliasType: 'PHONE',
			searchKey: '+852',
			processing: false
		}
		const initData = async () => {
			const types = this.aliasTypes
			if (types !== undefined && types.length > 0) {
				ctx.types = types
				ctx.aliasType = types[0]
			}
			if (this.order) {
				ctx.users = formatOrderUser(this.order)
			}
		}
		initData()
		return ctx
	},
	beforeMount() {
		window.addEventListener('beforeunload', this.preventNav)
	},
	beforeDestroy() {
		window.removeEventListener('beforeunload', this.preventNav)
	},
	methods: {
		search: _.debounce(async function () {
			if (!this.searchKey || this.searchKey.length <= 5) {
				this.results = []
				return
			}
			try {
				// Only verified user can be find for order assign
				const response = await getUsers({
					userAliasType: this.aliasType,
					userAliasId: this.searchKey
				})
				this.results = response.data.map(data => {
					const { id, isVerified } = data
					const { name } = data.info
					const phone = (
						data.aliases.find(i => i.type === 'PHONE') || {}
					).value
					return { id, isVerified, name, phone }
				})
			} catch (e) {
				log.error('User search', { category: 'API' }, e.stack)
				alert(e)
			}
		}, 1000),
		async add(user) {
			this.searchKey = '+852'
			this.results = []
			let index = this.results.findIndex(i => i.id === user.id)
			if (index > -1) {
				this.results.splice(index, 1)
			}
			index = this.order.userIds.indexOf(user.id)
			if (index > -1) {
				alert(`${user.name} already in order`)
				return
			}
			this.order.userIds.push(user.id)
			this.users.push(user)
			await this.patch()
		},
		async remove(id) {
			let index = this.order.userIds.indexOf(id)
			if (index > -1) {
				this.order.userIds.splice(index, 1)
			}
			index = this.users.findIndex(i => i.id === id)
			try {
				await this.patch()
				if (index > -1) {
					this.users.splice(index, 1)
				}
			} catch (e) {
				alert(e)
			}
		},
		// async triggerMatchmaking() {
		// 	try {
		// 		await axiosApiClient({
		// 			method: 'GET',
		// 			url: `/orders/${this.order.id}/matchmaking`
		// 		})
		// 		this.processing = true

		// 		// Implementing a real job status check/update is not worth it for now.
		// 		// But usually a job finishes in less than 200ms so 1sec should be enough :)
		// 		// 200ms would be "too quick" for the user anyway, better he thinks the computer is working real hard.
		// 		setTimeout(async () => {
		// 			await this.refreshCouriers()
		// 			this.processing = false
		// 		}, 1000)
		// 	} catch (e) {
		// 		log.error(
		// 			'Error triggering matchmaking for this order',
		// 			null,
		// 			e.stack
		// 		)
		// 		throw e
		// 	}
		// },
		async refreshCouriers() {
			try {
				const res = await axiosApiClient({
					method: 'GET',
					url: `/orders/${this.order.id}`
				})
				this.users = formatOrderUser(res.data)
			} catch (e) {
				log.error('Error on GET /orders', null, e.stack)
				throw e
			}
		},
		async patch() {
			const { userIds } = this.order
			const data = { userIds }
			try {
				await axiosApiClient({
					method: 'PATCH',
					url: `/orders/${this.order.id}`,
					data
				})
			} catch (e) {
				log.error('Error on PATCH /orders', null, e.stack)
				throw e
			}
		},
		preventNav(event) {
			if (this.results.length === 0) {
				return
			}
			event.preventDefault()
			// Chrome requires returnValue to be set.
			event.returnValue = ''
		}
	}
}
</script>
<style scoped>
#users,
#result {
	width: 100%;
}
#search {
	margin-top: 5px;
	width: 100%;
}
.searchText {
	width: 70%;
}
#types {
	margin-right: 10px;
	width: 26%;
}
#remove {
	cursor: pointer;
}
#add {
	float: right;
}

.trigger-matchmaking {
	display: block;
	background-color: #efc8b4;
	padding: 0.5em;
	margin: 0 auto 1em auto;
	height: auto;
}
</style>
