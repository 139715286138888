<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Layout from '@/components/Layout.vue'
import RawDetails from './components/RawDetails.vue'
import OrderStatusBadge from '@/components/OrderStatusBadge.vue'
import OrdersOverview from './components/OrdersOverview.vue'
import { rolePermissionCheck } from '@/permission/rolePermission'

export default {
	name: 'OrdersDetails',
	components: { Layout, OrderStatusBadge, RawDetails, OrdersOverview },
	data() {
		return {
			id: parseInt(this.$route.params.id),
			tab: 'overview',
			permissions: {
				edit: rolePermissionCheck('orders', 'edit')
			}
		}
	},
	computed: {
		...mapState('orders', ['orderInfo']),
		...mapGetters('orders', ['formatDate', 'orderRef', 'lastUpdatedAt'])
	},
	async created() {
		await this.getOrderById(this.id)
	},
	methods: {
		...mapActions('orders', ['getOrderById'])
	}
}
</script>

<template>
	<layout :title="`Orders: ${id}`">
		<div class="order-title">
			<h3>
				ORDER REF ID: {{ orderRef }} [{{ orderInfo.deliveryStage }}]
			</h3>
			<h4>
				<order-status-badge :status="orderInfo.status" />
			</h4>
			<q-btn
				v-if="permissions.edit"
				:to="`edit/${id}`"
				label="Edit"
				color="primary"
				no-caps
			/>
		</div>
		<div class="last-updated-time">
			Last updated at
			{{ lastUpdatedAt }}
		</div>
		<q-tabs
			:value="tab"
			align="left"
			class="q-ma-none q-gutter-y-md"
			content-class="q-ma-none"
			active-color="primary"
			dense
			@input="val => (tab = val)"
		>
			<q-tab name="overview" label="Overview" />
			<q-tab name="raw-data" label="Raw Data" />
		</q-tabs>
		<q-tab-panels v-model="tab">
			<q-tab-panel name="overview">
				<orders-overview />
			</q-tab-panel>
			<q-tab-panel name="raw-data">
				<raw-details />
			</q-tab-panel>
		</q-tab-panels>
	</layout>
</template>

<style scoped lang="scss">
.last-updated-time {
	margin-top: 5px;
	margin-bottom: 10px;
}

.order-title {
	display: flex;
	align-items: center;

	h3,
	h4 {
		margin: 0;
	}

	h4 {
		margin-left: 10px;
	}

	.q-btn {
		margin-left: auto;
		margin-right: 15px;
	}
}
</style>
