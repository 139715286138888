<script>
import _ from 'lodash'
import moment from 'moment'
import DeliveryStatusBadge from '@/components/DeliveryStatusBadge.vue'
import { mapGetters } from 'vuex'
import { DELIVERY_STATUS, DELIVERY_STATUS_GROUP } from '@/lib/utils/constants'

export default {
	name: 'ParcelHeader',
	components: { DeliveryStatusBadge },
	props: {
		delivery: {
			type: Object,
			required: true
		}
	},
	computed: {
		...mapGetters('orders', ['formatDate']),
		sla() {
			return this.slaDisplay(this.delivery)
		}
	},
	methods: {
		// Translate time difference in minutes to human readable relative time:
		parseDiff(diffMin) {
			let minutes = Math.abs(diffMin)
			let hours = Math.trunc(minutes / 60)
			minutes = minutes % 60
			const days = Math.trunc(hours / 24)
			hours = hours % 24
			const daysText = days ? `${days} days` : ''
			const hoursText = hours ? `${hours} hours` : ''
			const minutesText = minutes ? `${minutes} minutes` : ''
			return `${daysText} ${hoursText} ${minutesText}` || 'now'
		},
		slaDisplay(rec) {
			const sla = moment.utc(rec.deliveryBy)
			if (
				_.includes(
					[
						...DELIVERY_STATUS_GROUP.PENDING,
						...DELIVERY_STATUS_GROUP.INTERIM,
						...DELIVERY_STATUS_GROUP.IN_TRANSIT
					],
					rec.status
				)
			) {
				const diffMin =
					Math.trunc(sla.unix() / 60) -
					Math.trunc(moment.utc().unix() / 60)
				const diffText = this.parseDiff(diffMin)
				if (diffMin > 0) {
					return {
						text: `SLA in ${diffText}`,
						late: diffMin < 60
					}
				}
				if (diffMin < 0) {
					return {
						text: `Missed SLA by ${diffText}`,
						late: true
					}
				}
				return {
					text: 'SLA is now',
					late: true
				}
			}

			if (_.includes(DELIVERY_STATUS_GROUP.END, rec.status)) {
				const diffMin =
					Math.trunc(sla.unix() / 60) -
					Math.trunc(moment.utc(rec.updatedAt).unix() / 60)
				const diffText = this.parseDiff(diffMin)
				if (diffMin > 0) {
					return {
						text: `${diffText} before SLA`,
						late: false
					}
				}
				if (diffMin < 0) {
					return {
						text: `${diffText} after SLA`,
						late: true
					}
				}
				return {
					text: 'SLA met',
					late: false
				}
			}

			if (_.includes([DELIVERY_STATUS.CANCELED], rec.status)) {
				return { text: '', late: false }
			}
		}
	}
}
</script>

<template>
	<div class="row expand-header">
		<div class="col self-center">
			<h5 class="text-weight-bold">
				Parcel Ref ID: {{ delivery.parcelRef }}
			</h5>
		</div>
		<div class="col self-center">
			<delivery-status-badge :status="delivery.status" />
			<div>
				{{ formatDate(delivery.updatedAt) }}
			</div>
			<div v-if="delivery.reason">{{ delivery.reason }}</div>
		</div>
		<div v-if="delivery.hasSla" class="col self-center">
			{{ formatDate(delivery.deliveryBy) }}
			<div :class="{ 'text-danger': sla.late }">
				{{ sla.text }}
			</div>
		</div>
		<div v-if="!delivery.hasSla" class="col self-center">N/A</div>
	</div>
</template>

<style scoped>
.expand-header {
	width: 100%;
	margin-left: 0;
}
</style>
