<script>
import _ from 'lodash'
import { DELIVERY_STATUS } from '@/lib/utils/constants'

export default {
	name: 'DeliveryStatusBadge',
	props: {
		status: {
			type: String,
			default: ''
		}
	},
	computed: {
		statusColor() {
			// Maps delivery status to badge color
			const has = statues => status => _.includes(statues, status)
			return _.cond([
				[has([DELIVERY_STATUS.AWAITS_DRIVER]), _.constant('warning')],
				[
					has([
						DELIVERY_STATUS.AWAITS_PICKUP,
						DELIVERY_STATUS.IN_TRANSIT
					]),
					_.constant('info')
				],
				[has([DELIVERY_STATUS.DELIVERING]), _.constant('secondary')],
				[has([DELIVERY_STATUS.DELIVERED]), _.constant('positive')],
				[
					has([
						DELIVERY_STATUS.FAILED,
						DELIVERY_STATUS.FAILED_PICKUP
					]),
					_.constant('negative')
				],
				[_.stubTrue, _.constant('accent')]
			])(this.status)
		}
	}
}
</script>

<template>
	<q-badge :color="statusColor">
		{{ status }}
	</q-badge>
</template>
