<script>
import _ from 'lodash'
import { ORDER_STATUS } from '@/lib/utils/constants'

export default {
	name: 'OrderStatusBadge',
	props: {
		status: {
			type: String,
			default: ''
		}
	},
	computed: {
		statusColor() {
			// Maps order status to badge color
			const has = statues => status => _.includes(statues, status)
			return _.cond([
				[
					has([ORDER_STATUS.NOT_DONE, ORDER_STATUS.IN_PROGRESS]),
					_.constant('info')
				],
				[
					has([ORDER_STATUS.PARTIAL_DELIVERED]),
					_.constant('secondary')
				],
				[
					has([
						ORDER_STATUS.DONE,
						ORDER_STATUS.DELIVERED,
						ORDER_STATUS.COMPLETED
					]),
					_.constant('positive')
				],
				[
					has([ORDER_STATUS.CANCELED, ORDER_STATUS.CLOSED]),
					_.constant('negative')
				],
				[_.stubTrue, _.constant('accent')]
			])(this.status)
		}
	}
}
</script>

<template>
	<q-badge :color="statusColor">
		{{ status }}
	</q-badge>
</template>
