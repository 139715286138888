<script>
import _ from 'lodash'
import S from 'string'
import Vue from 'vue'
import moment from 'moment-timezone'
import axiosApiClient from '@/api/axiosApiClient'
import Tree from '@/components/Tree.vue'
import OrderRequestUserSearch from '@/components/OrderRequestUserSearch.vue'
import { getLocationTimezone } from '@/api/locations-api'

const options = {
	name: 'Orders',
	resource: 'orders',
	recordQueryParam: {},
	modifier: async (ctx, data) => {
		let aliasTypes = []
		try {
			const response = await axiosApiClient.get(`/users/schemas/post`)
			aliasTypes =
				response.data.body.items.properties.aliases.items.properties
					.type.enum
		} catch (error) {
			alert(error.message)
		}
		const pickupRequest = _.assign({}, data)
		const componentData = {
			computed: {
				order: () => pickupRequest,
				aliasTypes: () => aliasTypes
			}
		}
		const component = new Vue(
			_.assign({}, OrderRequestUserSearch, componentData)
		)
		// delete data.status
		delete data.userIds
		data.userIds = component
		let { clientRefs } = data
		clientRefs = _.groupBy(clientRefs, 'type')
		data.clientRefs = {}
		const keys = _.keys(clientRefs).sort()
		for (const key of keys) {
			data.clientRefs[key] = _.map(clientRefs[key], 'value')
		}

		// show some date in order timezone
		const tz = await getLocationTimezone(data.locationId)
		_.merge(data, {
			pickupAt: `rawdate-${moment(data.pickupAt)
				.tz(tz)
				.format('YYYY-MM-DD HH:mm (UTCZ)')}`,
			deliveryBy: `rawdate-${moment(data.deliveryBy)
				.tz(tz)
				.format('YYYY-MM-DD HH:mm (UTCZ)')}`
		})
		return data
	}
}

export default {
	name: 'RawDetails',
	components: { Tree },
	data() {
		const { query } = this.$route
		if (query.isDeleted !== undefined) {
			options.recordQueryParam.isDeleted =
				query.isDeleted === true || query.isDeleted === 'true'
		}
		const recordId =
			options.recordId === undefined
				? this.$route.params.id
				: options.recordId
		let path = this.$route.path
		Object.values(this.$route.params).forEach(v => {
			path = _.replace(path, v, '')
		})
		path = _.replace(path, /\//g, '')
		const ctx = {
			data: {},
			id: options.id || 'id',
			name: `${
				options.name || S(options.resource).humanize().s
			}: ${recordId}`,
			editEndpoint: `${path}/edit/${recordId}`,
			permissions: {},
			mapping:
				options.display === undefined
					? []
					: options.display.map(k => ({
							header:
								k === 'dockId' ? 'Dock ID' : S(k).humanize().s,
							key: k
					  })),
			modifier: options.modifier || (async (ctx, data, store) => data),
			humanize: S,
			canEdit: options.canEdit === undefined ? true : options.canEdit,
			canDelete:
				options.canDelete !== undefined ? options.canDelete : true,
			recordId,
			recordQueryParam: options.recordQueryParam || {}
		}

		ctx.canUndo = !!localStorage.getItem(`/${ctx.editEndpoint}`)

		const initData = async () => {
			const response =
				this.response ||
				(await axiosApiClient({
					url: `${options.resource}/${ctx.recordId}`,
					params: ctx.recordQueryParam
				}))
			const isMappingDefined = ctx.mapping.length > 0
			const data = await ctx.modifier(ctx, response.data, this.$store)
			ctx.data = data
			if (!isMappingDefined) {
				const keys = Object.keys(ctx.data)
				const mapping = keys.map(k => ({
					header: S(k).humanize().s,
					key: k
				}))
				ctx.mapping.push(...mapping)
			}
		}
		initData()
		return ctx
	}
}
</script>

<template>
	<div class="flex">
		<div id="divTable">
			<div id="divTableBody">
				<div v-for="m in mapping" id="divTableRow" :key="m.key">
					<tree :label="m.header" :nodes="data[m.key]" />
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.flex {
	display: flex;

	#divTable {
		display: table;
		width: 100%;
		text-align: left;

		#divTableBody {
			display: table-row-group;

			#divTableRow {
				display: table-row;
				width: 100%;

				#divTableCell {
					float: left;
					display: table-cell;
				}
				.key {
					font-weight: bold;
					width: 25%;
				}
			}
		}
	}
}
</style>
