<script>
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'
import { ORDER_STATUS_GROUP } from '@/lib/utils/constants'

export default {
	name: 'OrderEvents',
	computed: {
		...mapState('orders', ['orderEvents', 'orderInfo']),
		...mapGetters('orders', ['formatDate']),
		isEndedOrder() {
			return _.includes(ORDER_STATUS_GROUP.END, this.orderInfo.status)
		}
	},
	methods: {
		eventTypeHeader(event) {
			const eventType = event.type
			const parcelRef = _.get(event, 'payload.parcelRefs.0.value')
			const eventTypeStatus = {
				ORDER_CREATED: 'Order created',
				ORDER_CANCELED: 'Order canceled',
				ORDER_CLOSED: 'Order has been closed',
				ORDER_COMPLETED: 'Order has been completed',
				ORDER_DELIVERED: 'Order has been delivered',
				ORDER_PATCHED: 'Order updated details',
				ORDER_RESCHEDULED: 'Order updated pick-up time & delivery time',
				DELIVERY_DELIVERED: `Parcel (Ref#: ${parcelRef}) has been delivered`,
				DELIVERY_REDELIVERY_REQUESTED: `User updated pick-up time & delivery time (Parcel Ref#: ${parcelRef})`,
				DELIVERY_CANCELLATION_REQUESTED: `Parcel cancellation requested (Parcel Ref#: ${parcelRef})`,
				DELIVERY_CANCELED: `Parcel canceled(Parcel Ref#: ${parcelRef})`,
				DELIVERY_RETURNED: `Parcel (Ref#: ${parcelRef}) returned to warehouse`
			}
			return _.get(eventTypeStatus, eventType, eventType)
		}
	}
}
</script>

<template>
	<div class="order-events">
		<h5 class="text-weight-bold">Timeline</h5>
		<div class="events-container">
			<div v-for="event in orderEvents" :key="event.id" class="item">
				<div class="created-date">
					{{ formatDate(event.createdAt) }}
				</div>
				<div class="content">
					<div class="type">
						{{ eventTypeHeader(event) }}
					</div>
					<div class="extra-info">
						<div v-if="event.payload.pickupAt">
							<label>Pick-up Time</label>
							<span>
								:{{ formatDate(event.payload.pickupAt) }}
							</span>
						</div>
						<div v-if="event.payload.deliveryBy">
							<label>Delivery By</label>
							<span>
								:{{ formatDate(event.payload.deliveryBy) }}
							</span>
						</div>
					</div>
				</div>
			</div>
			<div v-if="!isEndedOrder" class="item" />
		</div>
	</div>
</template>

<style scoped lang="scss">
.order-events {
	.events-container {
		display: flex;
		flex-direction: column;
		margin-top: 20px;

		.item {
			display: flex;
			padding-bottom: 20px;
			position: relative;

			&:last-child {
				&::after {
					content: none;
				}
			}

			&::before {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				min-width: 20px;
				min-height: 20px;
				border-radius: 20px;
				background: lightgrey;
				margin-right: 10px;
				position: relative;
				z-index: 2;
			}

			&::after {
				content: '';
				height: calc(100% - 20px);
				width: 2px;
				background: lightgrey;
				position: absolute;
				z-index: 1;
				left: 9px;
				top: 20px;
			}

			.content {
				.type {
					font-weight: bold;
				}

				.extra-info {
					font-weight: normal;
					font-size: 12px;
					margin-left: 5px;

					label {
						margin: 0;
						width: 120px;
						font-weight: normal;
					}

					span {
						margin-left: 30px;
					}
				}
			}

			.created-date {
				width: 140px;
			}
		}
	}
}
</style>
